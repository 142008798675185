@import '../../../styles/commonStyles.scss';
.SideContent {
  position: relative;
  z-index: 992;
  z-index: 992;
  display: flex;
  flex-direction: row-reverse;
  height: 100%;
  :global(.ant-tabs-content-holder) {
    flex: 1;
  }
}
.IconClose {
  position: absolute;
  top: 20px;
  left: -15px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: var(--grey3);
  background: var(--grey3);
  color: var(--icon-color1);
  line-height: 1;
  text-align: center;
  cursor: pointer;
}
.RightTabPanel {
  overflow-x: hidden;
  overflow-y: hidden;
  height: calc(100vh - 68px); // 68px bottom fixed
  border-radius: 16px 10px 10px 16px;
}
.RightTabPanelContainer {
  overflow-y: auto;
  height: 100%;
  border: 1px solid var(--border9);
  border-radius: 16px 0px 0px 16px;
}
.RightTabPanelNotScroll {
  overflow-y: hidden;
}
.TabPanel {
  position: relative;
  display: none;
  width: 328px;
  height: 100%;
  padding-bottom: 0;
  background: var(--background);
}
.tabPanelOpen {
  display: block !important;
}
