.app-provider {
  position: relative;
  width: 100%;
  height: 100%;
}
.app-loading {
  position: absolute;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 4, 12, 0.9);
}
:root {
  --background: rgba(23, 23, 29, 0.85);
  --background2: rgba(0, 0, 0, 0.32);
  --background3: #000000;
  --background4: rgba(255, 255, 255, 0.01);
  --background5: rgba(255, 255, 255, 0.04);
  --background6: #070708;
  --background7: rgba(0, 0, 0, 0.5);
  --background-second: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.02),
      rgba(255, 255, 255, 0.02)
    ),
    rgba(28, 28, 36, 0.8);
  --background8: rgba(0, 0, 0, 0.12);
  --background9: #28282e;
  --background10: linear-gradient(
    223.74deg,
    rgba(0, 0, 0, 0.6) 2.11%,
    rgba(0, 0, 0, 0) 52.82%
  );
  --background11: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.02),
      rgba(255, 255, 255, 0.02)
    ),
    rgba(28, 28, 36, 0.8);
  --background12: rgba(255, 255, 255, 0.08);
  --background13: #000000;
  --background14: rgba(0, 0, 0, 0.32);
  --background15: rgba(0, 0, 0, 0.32);
  --background16: #000000;
  --background17: rgba(8, 90, 225, 0.75);
  --background18: #085ae1;
  --background19: rgba(0, 0, 0, 0.72);
  --background20: #383839;
  --background21: rgba(255, 255, 255, 0.16);
  --background22: #1e346a;
  --background23: #444448;
  --background24: rgba(0, 0, 0, 0.56);
  --background25: rgba(64, 152, 255, 0.2);
  --background26: rgba(255, 255, 255, 0.24);
  --background27: #1b1b1c;
  --background28: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.02),
      rgba(255, 255, 255, 0.02)
    ),
    rgba(28, 28, 36, 0.8);
  --background29: rgba(255, 255, 255, 0.04);
  --background30: rgba(255, 255, 255, 0.08);
  --background31: rgba(0, 0, 0, 0.2);
  --background32: rgba(0, 0, 0, 0.12);

  --background33: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
    ),
    rgba(23, 23, 29, 0.85);
  --background34: #17171b;
  --background35: rgba(0, 0, 0, 0.08);
  --background36: rgba(255, 255, 255, 0.08);
  --background37: #1c1c22;
  --background38: rgba(0, 0, 0, 0.32);
  --background39: #413f3f;
  --background40: #2d3039;
  --background41: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
    ),
    rgba(23, 23, 29, 0.96);
  --background43: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.02),
      rgba(255, 255, 255, 0.02)
    ),
    rgba(28, 28, 36, 0.96);
  --background42: rgba(4, 16, 27, 0.48);
  --background-qrcode: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.16) 0%,
      rgba(255, 255, 255, 0.16) 100%
    ),
    #000;
  --background-cropper-slider: rgba(0, 0, 0, 0.72);
  --background-primary24: rgba(38, 86, 201, 0.24);
  --background-layout-small: rgba(255, 255, 255, 0.04);
  --background-layout-small-hover: rgba(64, 152, 255, 0.2);
  --background-layout-small-active: rgba(64, 152, 255, 0.2);
  --background-slot-small: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.16),
      rgba(255, 255, 255, 0.16)
    ),
    rgba(0, 0, 0, 0.3);
  --background-slot-small-hover: linear-gradient(
      0deg,
      rgba(31, 176, 255, 0.45),
      rgba(31, 176, 255, 0.45)
    ),
    linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
    rgba(0, 0, 0, 0.3);
  --background-slot-small-active: linear-gradient(
      0deg,
      rgba(31, 176, 255, 0.45),
      rgba(31, 176, 255, 0.45)
    ),
    linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
    rgba(0, 0, 0, 0.3);
  --background-popover-setting: #4d505b;
  --background-popover-setting1: #595b65;
  --background-dot: rgba(0, 0, 0, 0.12);
  --background-tag: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.05),
      rgba(255, 255, 255, 0.05)
    ),
    rgba(23, 23, 29, 0.85);
  --background-menu-hover: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.08),
      rgba(255, 255, 255, 0.08)
    ),
    rgba(28, 28, 36, 0.7);
  --background-hover-primary: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
    ),
    #085ae1;
  --background-active-primary: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3)
    ),
    #085ae1;
  --background-button-1: #131314;
  --background-input-disabled: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.05),
      rgba(255, 255, 255, 0.05)
    ),
    rgba(0, 0, 0, 0.2);
  --background-input-disabled1: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.05),
      rgba(255, 255, 255, 0.05)
    ),
    rgba(0, 0, 0, 0.2);
  --button-dark: #070708;
  --button-dark-active: #393939;
  --button-dark-hover: #1b1b1c;
  --background-hover-dark: #1b1b1c;
  --background-option-hover: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0.1) 100%
    ),
    rgba(255, 255, 255, 0.08);
  --background-option-pressed: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.64) 0%,
      rgba(0, 0, 0, 0.64) 100%
    ),
    rgba(255, 255, 255, 0.08);
  --background-active-dark: #393939;
  --button-grey: #333339;
  --button-grey-active: #101011;
  --button-grey-hover: #48484e;
  --button-grey-disabled: #2a2a31;
  --button-danger: #ff625b;
  --button-danger-active: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3)
    ),
    #f3392a;
  --button-danger-hover: linear-gradient(
      0deg,
      rgba(255, 189, 189, 0.32) 0%,
      rgba(255, 189, 189, 0.32) 100%
    ),
    #ff5b4e;
  --background-image: none;
  --background-menu: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.08),
      rgba(255, 255, 255, 0.08)
    ),
    rgba(28, 28, 36, 0.7);
  --background-modal: #1d1e25;
  --background-modal1: #2f2f35;
  --background-dropdown: #373c43;
  --background-tab-panel: rgba(23, 23, 29, 0.85);
  --background-composer: rgba(255, 255, 255, 0.01);
  --background-option-selector-disabled: rgba(255, 255, 255, 0.05);
  --background-tabbar-card: rgba(5, 6, 18, 0.32);
  --background-tabitem-card-active: rgba(255, 255, 255, 0.16);
  --background-scale-btn: rgba(255, 255, 255, 0.08);
  --text-primary: #fff;
  --text-primary1: #e1e1e9;
  --text-secondary: rgba(225, 225, 233, 0.5);
  --text-secondary1: rgba(225, 225, 233, 0.75);
  --text-secondary2: rgba(225, 225, 233, 0.5);
  --text-secondary3: rgba(225, 225, 233, 0.75);
  --text-secondary4: rgba(225, 225, 233, 0.25);
  --text-secondary5: rgba(225, 225, 233, 0.75);
  --text-secondary6: rgba(225, 225, 233, 0.54);
  --text-secondary7: rgba(225, 225, 233, 0.25);
  --text-secondary8: rgba(225, 225, 233, 0.72);
  --text-secondary9: rgba(225, 225, 233, 0.72);
  --text-secondary10: rgba(225, 225, 233, 0.54);
  --text-secondary11: #e1e1e940;
  --text-secondary12: #e1e1e9;

  --text-link: #4098ff;
  --grey: rgba(225, 225, 233, 0.5);
  --grey1: linear-gradient(
      0deg,
      hsla(0, 0%, 100%, 0.15),
      hsla(0, 0%, 100%, 0.15)
    ),
    rgba(28, 28, 36, 0.15);
  --grey2: rgba(255, 255, 255, 0.08);
  --grey3: #28282c;
  --grey4: rgba(0, 0, 0, 0.8);
  --grey5: rgba(255, 255, 255, 0.1);
  --grey6: rgba(255, 255, 255, 0.25);
  --grey7: rgba(255, 255, 255, 0.16);
  --grey8: rgba(255, 255, 255, 0.04);
  --grey9: rgba(255, 255, 255, 0.04);
  --grey10: rgba(255, 255, 255, 0.1);
  --grey11: rgba(0, 0, 0, 0.8);
  --grey12: rgba(255, 255, 255, 0.16);
  --grey13: rgba(0, 0, 0, 0.4);
  --grey14: rgb(28, 28, 28);
  --grey15: rgb(59 59 59 / 80%);
  --grey16: rgba(255, 255, 255, 0.08);
  --grey17: rgba(255, 255, 255, 0.24);
  --grey18: #707274;
  --grey19: rgba(0, 0, 0, 0.72);
  --grey20: rgba(225, 225, 233, 0.4);
  --box-shadow: 0px 2px 2px rgb(0 0 0 / 25%),
    inset 0px -1px 1px rgb(255 255 255 / 10%);
  --box-shadow-live: none;
  --box-shadow-preview: none;
  --box-shadow-room-bottom: 0px 0px 8px rgba(1, 2, 11, 0.56);
  --font-family: 'Roboto', sans-serif;
  --right-tab-width: 72px;
  --icon-color: #e1e1e9;
  --icon-color1: #e1e1e9;
  --icon-color2: #e1e1e9;
  --icon-color3: rgba(225, 225, 233, 0.5);
  --icon-color4: #337baf;
  --icon-color5: rgba(225, 225, 233, 0.5);
  --icon-color6: #e1e1e9;
  --icon-color7: #e1e1e9;
  --icon-color-8: rgba(225, 225, 233, 0.88);
  --icon-color-9: #fff;
  --icon-selected-menu: #4098ff;
  --active-menu: #4098ff;
  --icon-disabled: #ffffff;
  --pt-icon: 2px;
  --green: #34a853;
  --room-app-color: #fff;
  --app-color: #e1e1e9;
  --tab-panel-title: rgba(225, 225, 233, 0.75);
  --tab-item-hover-color: rgba(225, 225, 233, 0.75);
  --border-title-item: none;
  --shadow: none;
  --shadow-title-item: 0px 2px 2px rgb(0 0 0 / 25%),
    inset 0px -1px 1px rgb(255 255 255 / 10%);
  --box-shadow-setting: 0px 2px 2px rgba(0, 0, 0, 0.25),
    inset 0px -1px 1px rgba(255, 255, 255, 0.1);
  --box-shadow-navbar: 0px 0px 8px rgba(1, 2, 11, 0.56);
  --box-shadow-dropdown: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
    ),
    rgba(23, 23, 29, 0.85);
  --box-shadow-datepicker: 0px 4px 20px rgba(0, 0, 0, 0.4);
  --scrollbar-thumb: rgba(119, 125, 141, 0.35);
  --scrollbar: rgba(0, 0, 0, 0.25);
  --red-contained: #ff808f;
  --red-on-contained: #6c0303;
  --green-contained: #a8d486;
  --green-on-contained: #1a380a;
  --blue-contained: #085ae1;
  --blue-on-contained: #ffffff;
  --blue-container-hover: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
    ),
    #085ae1;
  --blue-container-active: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3)
    ),
    #085ae1;
  --status-error: #ff625b;
  --status-error1: rgba(255, 98, 91, 0.75);
  --status-error2: #ff625b;
  --status-warning: #ff915a;
  --border: rgba(255, 255, 255, 0.08);
  --border1: rgba(255, 255, 255, 0.1);
  --border2: rgba(255, 255, 255, 0.16);
  --border3: rgba(255, 255, 255, 0.08);
  --border4: rgba(255, 255, 255, 0.24);
  --border5: rgba(255, 255, 255, 0.36);
  --border6: rgba(255, 255, 255, 0.08);
  --border7: rgba(255, 255, 255, 0.16);
  --border8: none;
  --border9: rgba(255, 255, 255, 0.08);
  --border10: rgba(255, 255, 255, 0.04);
  --border11: rgba(0, 0, 0, 0.32);
  --border12: rgba(255, 255, 255, 0.1);
  --border-not-in-month-cell: none;
  --border-today-cell: rgba(255, 255, 255, 0.24);
  --slider: rgba(255, 255, 255, 0.24);
  --slider1: rgba(255, 255, 255, 0.24);
  --slider-track: rgba(255, 255, 255, 0.24);
  --mr: -18px;
  --banner: url(./assets/images/SessionBannerDark.png);
  --clock-text: rgba(0, 0, 0, 1);
  --dropdown-box-shadow: 0px 0px 8px 0px rgba(1, 2, 11, 0.56);
  --nav-background: linear-gradient(
      0deg,
      rgba(28, 28, 36, 0.96),
      rgba(28, 28, 36, 0.96)
    ),
    linear-gradient(0deg, rgba(255, 255, 255, 0.02), rgba(255, 255, 255, 0.02));
}

[data-theme='light'] {
  --background: #ffffff;
  --background2: #ffffff;
  --background3: #ffffff;
  --background4: #f3f2f2;
  --background5: #f3f2f2;
  --background6: linear-gradient(
      0deg,
      rgba(8, 12, 49, 0.08),
      rgba(8, 12, 49, 0.08)
    ),
    #f3f2f2;
  --background7: linear-gradient(
      0deg,
      rgba(8, 12, 49, 0.04),
      rgba(8, 12, 49, 0.04)
    ),
    #f3f2f2;
  --background8: #f9f9f9;
  --background9: #f9f9f9;
  --background10: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.45) 0%,
    rgba(0, 0, 0, 0.3645) 10.67%,
    rgba(0, 0, 0, 0) 34.21%
  );
  --background11: #ffffff;
  --background12: #eae9ea;
  --background13: #ffffff;
  --background14: #ffffff;
  --background15: #f3f2f2;
  --background16: #f3f2f2;
  --background17: rgba(38, 86, 201, 0.75);
  --background18: #2656c9;
  --background19: #e0e0e3;
  --background20: #eae9ea;
  --background21: #eae9ea;
  --background22: #e0e0e3;
  --background23: #eae9ea;
  --background24: #ffffff;
  --background25: rgba(38, 86, 201, 0.24);
  --background26: #333333;
  --background27: #434343;
  --background28: #ffffff;
  --background29: #f9f9f9;
  --background30: #ffffff;
  --background31: #ffffff;
  --background32: transparent;
  --background33: #ffffff;
  --background34: #ffffff;
  --background35: #ffffff;
  --background36: linear-gradient(
      0deg,
      rgba(8, 12, 49, 0.04),
      rgba(8, 12, 49, 0.04)
    ),
    #f3f2f2;
  --background37: #ffffff;
  --background38: #f9f9f9;
  --background39: linear-gradient(
      0deg,
      rgba(8, 12, 49, 0.04),
      rgba(8, 12, 49, 0.04)
    ),
    #f3f2f2;
  --background40: #fff;
  --background41: #fff;
  --background42: rgba(4, 16, 27, 0.48);
  --background43: #fff;
  --background-qrcode: #f3f2f2;
  --background-cropper-slider: #ffffff;
  --background-primary24: rgba(8, 90, 225, 0.24);
  --background-layout-small: #2b2929;
  --background-layout-small-hover: rgba(4, 16, 27, 0.06);
  --background-slot-small: rgba(4, 16, 27, 0.12);
  --background-slot-small-hover: rgba(4, 16, 27, 0.12);
  --background-second: #ffffff;
  --background-image: none;
  --background-tag: #f3f2f2;
  --background-menu: rgba(201, 230, 253, 1);
  --background-dot: rgba(4, 16, 27, 0.16);
  --background-menu-hover: linear-gradient(
      0deg,
      rgba(8, 12, 49, 0.04),
      rgba(8, 12, 49, 0.04)
    ),
    #f3f2f2;
  --background-dropdown: #ffffff;
  --background-tab-panel: #ffffff;
  --background-modal: #ffffff;
  --background-modal1: #ffffff;
  --background-composer: #ffffff;
  --background-option-selector-disabled: #f3f2f2;
  --background-tabbar-card: linear-gradient(
      0deg,
      rgba(8, 12, 49, 0.04),
      rgba(8, 12, 49, 0.04)
    ),
    #f3f2f2;
  --background-tabitem-card-active: #ffffff;
  --background-scale-btn: #f3f2f2;
  --background-layout-small: #f3f2f2;
  --background-layout-small-active: rgba(38, 86, 201, 0.24);
  --background-layout-small-hover: rgba(4, 16, 27, 0.06);
  --background-slot-small: rgba(4, 16, 27, 0.12);
  --background-slot-small-hover: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.25),
      rgba(255, 255, 255, 0.25)
    ),
    rgba(0, 0, 0, 0.3);
  --background-slot-small-active: rgba(38, 86, 201, 0.8);
  --background-popover-setting: rgba(4, 16, 27, 0.16);
  --background-popover-setting1: rgba(4, 16, 27, 0.08);
  --background-hover-primary: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
    ),
    #2656c9;
  --background-active-primary: linear-gradient(
      0deg,
      rgba(8, 12, 49, 0.36),
      rgba(8, 12, 49, 0.36)
    ),
    #2656c9;
  --background-hover-dark: linear-gradient(
      0deg,
      rgba(8, 12, 49, 0.04),
      rgba(8, 12, 49, 0.04)
    ),
    #f3f2f2;
  --background-option-hover: #f1f2f4;
  --background-option-pressed: #dcdfe5;
  --background-button-1: #f3f2f2;
  --background-input-disabled: #ffffff;
  --background-input-disabled1: rgba(239, 239, 240, 1);
  --button-dark: rgba(0, 0, 0, 0.8);
  --button-dark-active: #5c5c5c;
  --button-dark-hover: #434343;
  --button-grey: #f3f2f2;
  --button-grey-active: #e0e0e3;
  --button-grey-hover: #eae9ea;
  --button-grey-disabled: #f3f2f2;
  --button-danger: #ea4335;
  --button-danger-active: linear-gradient(
      0deg,
      rgba(44, 9, 9, 0.24),
      rgba(44, 9, 9, 0.24)
    ),
    #ea4335;
  --background-active-dark: linear-gradient(
      0deg,
      rgba(8, 12, 49, 0.08),
      rgba(8, 12, 49, 0.08)
    ),
    #f3f2f2;
  --text-primary: #0f0f0f;
  --text-primary1: #0f0f0f;
  --text-secondary: #606060;
  --text-secondary1: #606060;
  --text-secondary2: rgba(96, 96, 96, 0.5);
  --text-secondary3: #303030;
  --text-secondary4: #808080;
  --text-secondary5: #404040;
  --text-secondary6: #808080;
  --text-secondary7: #a2a2a2;
  --text-secondary8: #606060;
  --text-secondary9: #303030;
  --text-secondary10: #606060;
  --text-secondary11: #a1a1a1;
  --text-secondary12: #606060;
  --text-link: #2656c9;
  --grey: rgba(15, 15, 15, 0.75);
  --grey1: linear-gradient(0deg, #f3f2f2, #f3f2f2), rgba(15, 15, 15, 0.15);
  --grey2: #e5e4e4;
  --grey3: #f3f2f2;
  --grey4: linear-gradient(0deg, #f3f2f2, #f3f2f2), rgba(15, 15, 15, 0.15);
  --grey5: #f3f2f2;
  --grey6: #f3f2f2;
  --grey7: #e1e6ea;
  --grey8: #f3f2f2;
  --grey9: #e8eaed;
  --grey10: #e1e6ea;
  --grey11: #f3f2f2;
  --grey12: rgba(4, 16, 27, 0.08);
  --grey13: rgba(4, 16, 27, 0.08);
  --grey14: #f9f9f9;
  --grey15: #f4f4f4;
  --grey16: #f3f2f2;
  --grey17: rgba(4, 16, 27, 0.32);
  --grey18: #f3f2f2;
  --grey19: linear-gradient(0deg, rgba(8, 12, 49, 0.08), rgba(8, 12, 49, 0.08)),
    #f3f2f2;
  --box-shadow: none;
  --box-shadow-live: 0px 0px 12px rgba(8, 10, 20, 0.16);
  --box-shadow-room-bottom: 0px 0px 4px rgba(1, 2, 11, 0.24);
  --box-shadow-setting: none;
  --box-shadow-preview: 0px 4px 12px rgba(1, 2, 11, 0.08),
    0px 1px 4px rgba(1, 2, 11, 0.24);
  --box-shadow-navbar: 0px 0px 3px rgba(1, 2, 11, 0.24);
  --box-shadow-dropdown: 0px 4px 8px rgba(1, 2, 11, 0.12),
    0px 4px 12px rgba(1, 2, 11, 0.08), 0px 1px 4px rgba(1, 2, 11, 0.24);
  --box-shadow-datepicker: 0px 4px 12px rgba(1, 2, 11, 0.08),
    0px 1px 4px rgba(1, 2, 11, 0.24);

  --font-family: 'Roboto', sans-serif;
  --right-tab-width: 72px;
  --icon-color: #606060;
  --icon-color1: #0f0f0f;
  --icon-color2: #ffffff;
  --icon-color3: #606060;
  --icon-color4: #0f0f0f;
  --icon-color5: #0f0f0f;
  --icon-color6: #222222;
  --icon-color7: #808080;
  --icon-color-8: rgba(48, 48, 48, 1);
  --icon-color-9: #808080;
  --icon-selected-menu: #0e4077;
  --icon-disabled: #606060;
  --active-menu: #071c33;
  --pt-icon: 4px;
  --green: #34a853;
  --room-app-color: #000;
  --app-color: #0f0f0f;
  --tab-panel-title: #0f0f0f;
  --tab-item-hover-color: rgba(15, 15, 15, 1);
  --shadow: 0px 4px 12px rgba(1, 2, 11, 0.08), 0px 1px 4px rgba(1, 2, 11, 0.24);
  --shadow-title-item: none;
  --border-title-item: 1px solid #e1e6ea;
  --scrollbar-thumb: rgba(4, 16, 27, 0.24);
  --scrollbar: rgba(4, 16, 27, 0.08);
  --red-contained: #ffabb5;
  --red-on-contained: #af0c0c;
  --blue-contained: #2656c9;
  --blue-container-hover: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
    ),
    #2656c9;
  --blue-container-active: linear-gradient(
      0deg,
      rgba(8, 12, 49, 0.36),
      rgba(8, 12, 49, 0.36)
    ),
    #2656c9;
  --blue-on-contained: #ffffff;
  --green-contained: #b5ff9b;
  --green-on-contained: #005916;
  --status-error: #ff625b;
  --status-error1: rgba(234, 67, 53, 0.75);
  --status-error2: #ea4335;
  --status-warning: #f87803;
  --border: rgba(4, 16, 27, 0.08);
  --border1: rgba(4, 16, 27, 0.08);
  --border2: rgba(4, 16, 27, 0.16);
  --border3: rgba(4, 16, 27, 0.16);
  --border4: rgba(4, 16, 27, 0.16);
  --border5: rgba(4, 16, 27, 0.06);
  --border6: rgba(4, 16, 27, 0.06);
  --border7: rgba(4, 16, 27, 0.12);
  --border8: rgba(4, 16, 27, 0.06);
  --border9: rgba(4, 16, 27, 0.12);
  --border10: rgba(4, 16, 27, 0.06);
  --border11: rgba(4, 16, 27, 0.12);
  --border12: rgba(4, 16, 27, 0.06);
  --border-not-in-month-cell: rgba(4, 16, 27, 0.08);
  --border-today-cell: rgba(4, 16, 27, 0.48);
  --slider: rgba(4, 16, 27, 0.08);
  --slider: rgba(4, 16, 27, 0.12);
  --slider-track: #2656c9;

  --mr: -20px;

  --banner: url(./assets/images/SessionBannerLight.png);
  --dropdown-box-shadow: 0px 1px 4px 0px rgba(1, 2, 11, 0.24),
    0px 4px 12px 0px rgba(1, 2, 11, 0.08);
  --nav-background: #ffffff;
}
