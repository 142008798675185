@import 'normalize.css/normalize.css';
@import 'antd/dist/antd.css';
@import './Theme.scss';
@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Oswald:wght@200..700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'icomoon';
  src: url('./assets/fonts/icomoon.eot?4isbae');
  src:
    url('./assets/fonts/icomoon.eot?4isbae#iefix') format('embedded-opentype'),
    url('./assets/fonts/icomoon.ttf?4isbae') format('truetype'),
    url('./assets/fonts/icomoon.woff?4isbae') format('woff'),
    url('./assets/fonts/icomoon.svg?4isbae#icomoon') format('svg');
  font-display: block;
}
html {
  overflow: hidden;
  scroll-behavior: smooth;
}
html,
body {
  color: #e1e1e9;
  font-family: 'Poppins', sans-serif;
  ::-webkit-scrollbar-track {
    background-color: var(--scrollbar);
  }
  ::-webkit-scrollbar {
    display: flex;
    justify-items: center;
    width: 6px !important;
    height: 6px !important;
    background-color: var(--scrollbar);
  }
  ::-webkit-scrollbar-thumb {
    height: 50px;
    border-radius: 5px;
    background-color: var(--scrollbar-thumb);
  }
}
body.dragging {
  cursor: pointer;
}
.ant-dropdown-menu {
  overflow: auto !important;
  max-height: 400px !important;
  border-radius: 8px;
}
.menu-style {
  border-radius: 8px;
  background: #2d3039;
  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.25);
  }
  &::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
    background-color: rgba(0, 0, 0, 0.25);
  }
  &::-webkit-scrollbar-thumb {
    height: 50px;
    border-radius: 5px;
    background-color: rgba(119, 125, 141, 0.35);
  }
}

.menu-style .ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background: #413f3f;
}
.menu-style-light {
  border-radius: 8px;
  background: #ffffff;
  box-shadow:
    0px 4px 12px rgba(1, 2, 11, 0.08),
    0px 1px 4px rgba(1, 2, 11, 0.24);
  &::-webkit-scrollbar-track {
    background-color: #f3f2f2;
  }
  &::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
    background-color: #f3f2f2;
  }
  &::-webkit-scrollbar-thumb {
    height: 50px;
    border-radius: 5px;
    background-color: rgba(36, 39, 47, 0.35);
  }
}
.menu-style-light .ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background: linear-gradient(
      0deg,
      rgba(8, 12, 49, 0.04),
      rgba(8, 12, 49, 0.04)
    ),
    #f3f2f2;
}
#root {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0;
  font-weight: 400;
}

*,
*::before,
*::after {
  outline: none !important;
}
input {
  padding: 0;
  background-color: transparent;
  font-family: inherit;
}
.link {
  color: white;
  text-decoration: underline;
  cursor: pointer;
}
.hide-scrollbar {
  overflow: hidden;
}
.popover-color-picker .ant-popover-inner {
  padding: 0px;
  border-radius: 0px;
  background-color: transparent;
  box-shadow: none;
}

.ant-popover-inner {
  background-color: transparent;
}
.popover-color-picker .ant-popover-inner-content {
  padding: 0;
  background-color: transparent;
}
[draggable='true'] {
  cursor: move !important;
  /* fix bug drag overflow not hidden */
  transform: translate(0, 0);
}

.ant-tooltip {
  pointer-events: none;
}
.ant-select-dropdown.dropdownSelect {
  display: none;
}
h1,
.h1 {
  font-size: 44px;
}

/* Handle on hover */

h2,
.h2 {
  font-size: 36px;
}

h3,
.h3 {
  font-size: 24px;
}

h4,
.h4 {
  font-size: 20px;
}

h5,
.h5 {
  font-size: 16px;
}
h6,
.h6 {
  font-size: 14px;
}
.ant-popover.hide-arrow .ant-popover-arrow {
  display: none;
}
.ant-popover-inner-content {
  padding: 0;
  background: transparent;
}
.ant-message-notice-content {
  border-radius: 8px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
    ),
    rgba(23, 23, 29, 0.85);
  box-shadow:
    0px 2px 2px rgba(0, 0, 0, 0.25),
    inset 0px -1px 1px rgba(255, 255, 255, 0.1);
}
.ant-message-custom-content span {
  display: inline-block;
  margin-left: 10px;
  font-weight: 500;
}

.ant-message-custom-content.ant-message-success span {
  color: var(--green);
}
.ant-message-custom-content.ant-message-error span {
  color: var(--button-danger);
}
.selecto-selection {
  border: 2px solid #7589ff !important;
  border-radius: 4px;
  background-color: rgba(117, 137, 255, 0.2) !important;
}

/* Change the white to any color */
/* form */

.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper,
.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper:hover {
  border-color: var(--status-error2);
  background-color: none !important;
}
.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  order: 1;
  margin-left: 4px;
}

.ant-form-item-has-error .iconInput {
  display: inline-flex !important;
}

.textarea-icon-error {
  position: absolute;
  top: 17px;
  right: 12px;
}
.none {
  display: none;
}

.ant-modal-wrap {
  overflow: hidden;
}
.ant-modal {
  max-width: none;
}
.ant-form-item-with-help {
  margin-bottom: 16px !important;
}
.ant-form-item-explain > div {
  margin: 8px 0 !important;
}
.input-password .ant-form-item-with-help {
  margin: 0 !important;
}
.input-password .ant-form-item-explain {
  min-height: unset !important;
}

.popover-custom-arrow {
  padding-right: 15px;
}
.popover-custom-arrow .ant-popover-arrow {
  right: 6px;
  width: 16px;
  height: 16px;
}
.popover-custom-arrow .ant-popover-arrow-content {
  width: 16px;
  height: 16px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
    ),
    rgb(43 43 43 / 85%);
}

.ant-slider-handle::after {
  inset: -10px;
}
.noti-error-dark {
  border-radius: 8px;
  background: #2e2e34;
  color: rgba(225, 225, 233, 0.54);
  box-shadow: 0px 0px 8px rgba(1, 2, 11, 0.56);
  .ant-notification-notice-message {
    color: rgba(225, 225, 233, 0.54);
  }
  .ant-notification-notice-close-icon {
    color: rgba(225, 225, 233, 0.54);
  }
}
.noti-error-light {
  border-radius: 8px;
  background: #ffffff;
  color: #606060;

  box-shadow:
    0px 4px 12px rgba(1, 2, 11, 0.08),
    0px 1px 4px rgba(1, 2, 11, 0.24);
  .ant-notification-notice-message {
    color: #606060;
  }
  .ant-notification-notice-close-icon {
    color: #606060;
  }
}
.animation-spin {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.ant-dropdown-menu-item:hover {
  background-color: var(--grey2) !important;
}
.editor-container {
  position: relative;
  max-width: 600px;
  margin: 20px auto 20px auto;
  border-radius: 2px;
  border-radius: 10px;
  background: #fff;
  color: #000;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.editor-input {
  position: relative;
  min-height: 150px;
  padding: 10px 10px 5px;
  outline: 0;
  font-size: 15px;
  tab-size: 1;
  resize: none;
  caret-color: rgb(5, 5, 5);
  caret-color: #444;
}

.editor-placeholder {
  position: absolute;
  top: 10px;
  left: 10px;
  display: inline-block;
  overflow: hidden;
  color: #999;
  font-size: 15px;
  text-overflow: ellipsis;
  pointer-events: none;
  user-select: none;
}

.editor-paragraph {
  position: relative;
  margin: 0 0 15px 0;
}

.dropdown .item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  margin-right: 12px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  line-height: 16px;
  user-select: none;
}
.ant-modal-wrap {
  z-index: 1200 !important;
}
.ant-dropdown {
  z-index: 1200;
}
